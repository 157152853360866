import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../index";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(sagaMiddleware)
    // window.navigator.userAgent.includes("Chrome")
    //   ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //       window.__REDUX_DEVTOOLS_EXTENSION__()
    //   : compose
  )
);
sagaMiddleware.run(rootSaga);

export default store;
