import React from "react";
import Bottom from "./components/Bottom";
import Top from "./components/Top";

function Iso() {
  return (
    <section>
      <Top />
      <Bottom />
    </section>
  );
}

export default Iso;
