import React from "react";
import { useDispatch } from "react-redux";
import Fold from "./components/Fold";
import "./css/index.css";
function Main() {
  const dispatch = useDispatch();
  return (
    <div style={{ width: "100%" }} className="mainsub">
      {/* <Side /> */}
      <div className="section">
        <div className="wrapper">
          <div className="title">
            복잡한 정부지원금 <br />
            <span>김과장과 함께 쉽게!</span>
          </div>
          <div
            className="box"
            onClick={() => {
              dispatch({
                type: "config/isPopup",
                payload: {
                  state: true,
                  type: "main",
                },
              });
            }}
          >
            <div className="text">내정부 지원금찾기</div>
            <img src="/assets/sub/next.svg" alt="" />
          </div>
          <div className="back-img">
            <img
              src="/assets/sub/main.png"
              srcSet="/assets/sub/main@2x.png 2x, /assets/sub/main@3x.png 3x"
              alt=""
              className="pc"
            />
            <img
              src="/assets/sub/main-mb.png"
              srcSet="/assets/sub/main-mb@2x.png 2x, /assets/sub/main-mb@3x.png 3x"
              alt=""
              className="main-mb"
            />
          </div>
        </div>
      </div>
      <div className="section1">
        <div className="wrapper">
          <div className="title">내정부지원금을 찾아보세요!</div>
          <div className="box">
            {box.map((item, i) => {
              return (
                <div key={i} className={`boxlist ${item.type}`}>
                  <div className="title">{item.title}</div>
                  <div className="sub">{item.sub}</div>
                  <div className="img">{item.img}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="wrapper">
          <div className="wrapper2">
            <div className="left">
              <div className="title">
                믿고 받는 김과장컨설팅으로 <br />
                정부지원금 받으세요
              </div>
              <div className="title_tb">
                믿고 받는 <br />
                김과장컨설팅으로 <br />
                정부지원금 받으세요
              </div>
              <div
                className="box"
                onClick={() => {
                  dispatch({
                    type: "config/isPopup",
                    payload: {
                      state: true,
                      type: "main",
                    },
                  });
                }}
              >
                <div className="text">내정부 지원금찾기</div>
                <img src="/assets/sub/next.svg" alt="" />
              </div>
            </div>
            <div className="right">
              <img
                src="/assets/sub/good.png"
                srcSet="/assets/sub/good@2x.png 2x, /assets/sub/good@3x.png 3x"
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="section3">
        <div className="wrapper">
          <div className="title">
            김과장 컨설팅, <br /> 아직도 망설이시나요?
          </div>
          <div className="fold-wrapper">
            {foldarr.map(({ number, title, content }, idx) => {
              return (
                <Fold
                  key={idx}
                  content={content}
                  number={number}
                  title={title}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;

const box = [
  {
    title: (
      <div>
        2명 중 1명이 <br />
        정부지원금 받아요!
      </div>
    ),
    sub: (
      <div>
        2명 중 1명이 김과장 컨설팅으로 <br />
        정부지원금 받기 완료!
      </div>
    ),
    img: (
      <div>
        <img src="/assets/sub/icon.svg" alt="" />
      </div>
    ),
  },
  {
    title: (
      <div>
        카카오로 간편하게 조회!
        <br />
        믿을 수 있는 서비스!
      </div>
    ),
    sub: (
      <div>
        KDB산업은행 외 12개 사에서
        <br />
        인력 투자 유치 완료
      </div>
    ),
    img: (
      <div>
        <img src="/assets/sub/icon2.svg" alt="" />
      </div>
    ),
    type: "first",
  },
  {
    title: (
      <div>
        복잡한 과정 없이
        <br />
        5분이면 신청완료!!
      </div>
    ),
    sub: (
      <div>
        간단하지만 핵심 질문만 담은 설문으로
        <br />
        간편하게 상담 신청 완료!
      </div>
    ),
    img: (
      <div>
        <img src="/assets/sub/icon3.svg" alt="" />
      </div>
    ),
    type: "second",
  },
];
const foldarr = [
  {
    number: "Q1.",
    title: "정부지원금은 어떻게 입금되나요?",
    content: (
      <p>
        정부지원금은 신청 즉시 입금되는 것이 아닙니다.
        <br /> 신청서(사업계획서) 제출 후 심사를 거쳐 지급여부가 판가름됩니다.{" "}
        <br /> 이후 각 사업의 테마에 맞춰 현금, 바우처 등으로 지급됩니다.
        <br /> 김과장컨설팅은 각 정부지원사업에 최적화된 기획력으로 정부지원금
        수혜 가능하도록 컨설팅을 진행합니다.
      </p>
    ),
  },
  {
    number: "Q2.",
    title: "김과장컨설팅은 믿을 수 있는 곳인가요?",
    content: (
      <p>
        김과장컨설팅은 2019년 부터 중소기업 소상공인 대표님들과 함께한
        경영컨설팅 전문기업 입니다. <br /> 다양한 정부지원금, 정부지원사업을
        필드에서 경험하였으며 노하우를 바탕으로 합리적인 수수료 <br /> 로
        기업경영지원 서비스를 제공합니다.
      </p>
    ),
  },
  {
    number: "Q3.",
    title: "기업진단 서비스 신청 후 일정은 어떻게 되나요?",
    content: (
      <p>
        김과장컨설팅의 정부지원금 전문컨설턴트가 기업의 제반정보를 바탕으로{" "}
        다양한 지원사업을 기획합니다. <br /> 예비창업자의 경우 비즈니스모델의
        타당성을 입증한 후 정부지원사업 매칭여부를 결정하여 통보 해드립니다.{" "}
        <br /> 통상 2영업일 이내 분석리포트를 발송합니다.
      </p>
    ),
  },
];
